import React from "react"
import {Link} from "gatsby"

import PageTemplate from "../components/PageTemplate"
import styled, { createGlobalStyle } from "styled-components"


const GlobalStyles = createGlobalStyle`
  body {
    background-repeat: no-repeat;
     
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+58,d6ebf8+90,d6ebf8+90,d6ebf8+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(left,  #ffffff 0%, #ffffff 58%, #d6ebf8 90%, #d6ebf8 90%, #d6ebf8 100%) fixed !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 58%,#d6ebf8 90%,#d6ebf8 90%,#d6ebf8 100%) fixed !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 58%,#d6ebf8 90%,#d6ebf8 90%,#d6ebf8 100%) fixed !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d6ebf8',GradientType=1 ) !important; /* IE6-9 */
  }
`

const PageContent = styled.div`
  position: relative;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding-top: 135px;
  }
`
const PageContentInner = styled.div`
  padding-left: ${props => props.theme.paddingSidesMobile};
  padding-right: ${props => props.theme.paddingSidesMobile};
  padding-top: 100px;
  padding-bottom: 40px;
  
  @media (min-width: ${props => props.theme.breakpointTablet}) {
    padding-left: ${props => props.theme.paddingSidesDesktop};
    padding-right: ${props => props.theme.paddingSidesDesktop};
    padding-top: 0;
    padding-bottom: 0;
  }
`

const NotFoundPage = () => (

  <PageTemplate metaDataIndex={6}>
    <GlobalStyles />
    <PageContent>
      <PageContentInner role="main" id="main">

        <h1>We’re sorry! That page can’t be found.</h1>
        <h2>
          Looking to experience the difference?
          <br/>
          You might need to look on a different page.
        </h2>
        <p>The page you're looking for may have been removed, had its name changed, or is temporarily unavailable. Try one of the pages below to find what you’re looking for.</p>
        <ul>
          <li><Link to='/'>Home Page</Link></li>
          <li><Link to='/healthcare-mission-culture'>Mission & Culture</Link></li>
          <li><Link to='/pharmaceutical-advertising-agencies'>Agencies</Link></li>
          <li><Link to='/pharmaceutical-awards'>Awards</Link></li>
        </ul>
        <Link to='/'>Return Home</Link>

      </PageContentInner>
    </PageContent>
  </PageTemplate>
)

export default NotFoundPage
